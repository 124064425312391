import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { showMsg } from "../store/slices/alertsSlice.js";

import axios from "axios";
import Alerts from "../components/Alerts/Alerts";
import { Icons } from "../assets/icons";
import { isVlektra, sendEventToAndroid, sendEventToiOS } from "../helper";

import Camera from "../components/Camera/Camera.js";

function JobDetail() {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");

  const job_id = parseInt(queryParams.get("job_id"));
  const cid = queryParams.get("cid");
  const vtype = queryParams.get("vtype");
  const isFleet = queryParams.get("fleet") === "true" ? true : false;

  // for navigate
  let navigate = useNavigate();
  // store
  const { isAlert } = useSelector((state) => state.alertsSlice);
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [jobDetail, setJobDetail] = useState({});
  const [crewsNotes, setCrewNotes] = useState("");
  const [jobStatus, setJobStatus] = useState("");
  const [statusOptions, setStatusOptions] = useState([]);
  const [isCamera, setCamera] = useState(false);
  const [image, setImage] = useState("");
  const [hasPermission, setHasPermission] = useState(null);

  useEffect(() => {
    requestCameraPermission();
    fetchData();
  }, []);
  useEffect(() => {}, [hasPermission]);

  const requestCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setHasPermission(true);
      // Stop the stream to release the camera
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      setHasPermission(false);
    }
  };
  function fetchData() {
    const headers = {
      Authorization: `Bearer ${token}`,
      Domain: "Fleet",
    };
    const url = `${process.env.REACT_APP_SERVER}/api/v1/routes/vehicle-job-detail/${job_id}`;
    setLoader(true);
    axios
      .get(url, { headers })
      .then((res) => {
        setJobDetail(res.data.body);
        setCrewNotes(res.data.body.crew_notes);
        setJobStatus(res.data.body.status);
        setStatusOptions(makeStatusOptions(res.data.body));
      })
      .finally(() => setLoader(false));
  }
  function onNotesChange(e) {
    setCrewNotes(e.target.value);
  }
  function makeStatusOptions(v) {
    if (v.status === "pending") return ["pending", "progress"];
    else if (v.status === "progress") return ["progress", "completed"];
    else if (v.status === "completed") return ["completed"];
  }

  function getButtonClass(v, act) {
    if (act === "active") {
      if (v === "pending") return "btn btn-warning";
      if (v === "progress") return "btn btn-success";
      if (v === "completed") return "btn btn-primary";
    } else {
      if (v === "pending") return "btn btn-outline btn-warning";
      if (v === "progress") return "btn btn-outline btn-success";
      if (v === "completed") return "btn btn-outline btn-primary";
    }
  }
  function onStatusChange(value) {
    setJobStatus(value);
    if (value === "progress") {
      saveDetail("progress");
      // goToRoute({ auto: true });
    }
  }

  function base64ToFile(base64String, fileName) {
    // Ensure the fileName has a .jpg extension
    const fileExtension = fileName.split(".").pop();
    const isJpeg = fileExtension === "jpg" || fileExtension === "jpeg";

    // Extract the base64 data and MIME type from the base64 string
    const [header, data] = base64String.split(",");
    const mimeType = header.match(/:(.*?);/)[1];

    // Handle cases where the MIME type is not image/jpeg
    const correctedMimeType =
      mimeType === "image/jpeg" ? mimeType : "image/jpeg";
    const correctedFileName = isJpeg
      ? fileName
      : `${fileName.split(".")[0]}.jpg`;

    // Convert base64 to binary
    const binaryString = window.atob(data);
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryString.length; i++) {
      uintArray[i] = binaryString.charCodeAt(i);
    }

    return new File([arrayBuffer], correctedFileName, {
      type: correctedMimeType,
    });
  }
  async function saveDetail(st) {
    const headers = {
      Authorization: `Bearer ${token}`,
      Domain: "Fleet",
      "Content-Type": "multipart/form-data",
    };

    const formData = new FormData();
    formData.append("job_id", job_id);
    formData.append("crew_notes", crewsNotes);
    formData.append("status", st || jobStatus);
    formData.append("shipment_id", jobDetail.shipment_id);
    console.log(st, jobStatus);
    if (image) {
      const imageFile = base64ToFile(image, "shipment-slip.jpeg"); // Convert base64 to file
      formData.append("shipment_image", imageFile);
    }

    const url = `${process.env.REACT_APP_SERVER}/api/v1/routes/update-job`;
    setLoader(true);
    await axios
      .patch(url, formData, { headers })
      .then((res) => {
        console.log("Patch API Response::: ", res);
        if (st === "progress") {
          goToRoute({ auto: true });
        } else {
          sendEvent();
        }
      })
      .catch((err) => {
        const params = {
          type: "danger",
          msg: err?.response?.data?.message,
        };
        dispatch(showMsg(params));
      })
      .finally(() => setLoader(false));
    // sendEvent();
  }
  function sendEvent() {
    if (sendEventToAndroid("eventGoBack")) {
      window.Android.eventGoBack(
        String(
          `https://${window.location.host}/JobList?vtype=${vtype}&fleet=${isFleet}&cid=${cid}&job_id=${job_id}&token=${token}`
        ),
        String("Job List")
      );
    } else if (sendEventToiOS("eventGoBack")) {
      window.webkit.messageHandlers.eventGoBack.postMessage(
        String(
          `https://${window.location.host}/JobList?vtype=${vtype}&fleet=${isFleet}&cid=${cid}&job_id=${job_id}&token=${token}`
        ),
        String("Job List")
      );
    }
  }

  function goToRoute(row) {
    const auto = row?.auto ? true : false;
    return navigate(
      `/JobRoute?vtype=${vtype}&fleet=${isFleet}&cid=${cid}&job_id=${job_id}&token=${token}&autoplay=${auto}`
    );
  }
  const ContactInfo = ({ type, title, content }) => {
    return (
      <div className="contact-info">
        <div className="d-flex gap-1">
          <div className="icon-box">
            <img src={Icons[type]} width={"30px"} alt="missing graphics"></img>
          </div>
          <div className="info">
            <span className="title">{title}</span>
            <span className="content text-muted">{content}</span>
          </div>
        </div>
        {/* <div>button</div> */}
      </div>
    );
  };
  function openCamera() {
    setCamera(!isCamera);
  }
  // Handle photo taken in parent component
  function handlePhotoTaken(dataUri) {
    console.log("Photo received in App:", dataUri);
    setImage(dataUri);
    setCamera(false);
    // Perform any action with the captured photo URI here
  }

  return (
    <div className="container_wrapper">
      {isAlert && <Alerts />}

      {loader && (
        <div className="overlay">
          <div className="overlay__inner">
            <img
              src={
                isFleet
                  ? Icons.orko_loader
                  : isVlektra(vtype)
                  ? Icons.loader
                  : vtype === "sazgar-sazgar"
                  ? Icons.sazgar_loader
                  : Icons.ecodost_loader
              }
              className="overlay__content"
              width={"200px"}
              alt="missing graphics"
            ></img>
          </div>
        </div>
      )}
      {!loader && (
        <div className="screen-layout">
          {/* <div className="navbar">
          <div className="d-flex justify-center align-center">Job Detail</div>
        </div> */}
          <div className="job-detail">
            <div>
              <div className="d-flex align-center justify-space-between">
                <h3> {jobDetail.job_type}</h3>
                <div className="d-flex">
                  <div onClick={goToRoute}>
                    <img src={Icons.address} width={"30px"} alt="x" />
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-section">
              <span className="text-muted">Contact:</span>

              {jobDetail.location_name && (
                <ContactInfo
                  type={"address"}
                  title={"address"}
                  content={jobDetail.location_name}
                />
              )}
              {jobDetail.contact_details && (
                <ContactInfo
                  type={"phone"}
                  title={"phone"}
                  content={jobDetail.contact_details}
                />
              )}
            </div>
            {jobDetail.job_notes && (
              <div className="desc-section">
                <div>
                  <h3> Instructions</h3>
                </div>
                <div>{jobDetail.job_notes}</div>
              </div>
            )}

            <div className="desc-section">
              <div>
                <h3> Crew Notes</h3>
              </div>
              <textarea rows={5} value={crewsNotes} onChange={onNotesChange} />
            </div>

            <div className="d-flex justify-space-between align-center">
              <div className="status-section">
                {statusOptions.map((r, i) => {
                  if (r === jobStatus) {
                    return (
                      <div key={i}>
                        <button className={getButtonClass(r, "active")}>
                          {r === "progress"
                            ? "job started"
                            : jobDetail.status === "progress"
                            ? "complete"
                            : r}
                        </button>
                      </div>
                    );
                  } else {
                    return (
                      <div key={i}>
                        <button
                          onClick={() => onStatusChange(r)}
                          className={getButtonClass(r)}
                        >
                          {r === "progress"
                            ? "start job"
                            : jobDetail.status === "progress"
                            ? "complete"
                            : r}
                        </button>
                      </div>
                    );
                  }
                })}
              </div>

              {jobDetail.status !== "completed" &&
                !jobDetail.shipment_image && (
                  <div className="upload-section">
                    <div onClick={openCamera}>
                      <img src={Icons.imgAdd} width={"30px"} alt="x" />
                    </div>
                  </div>
                )}
            </div>
            {isCamera && (
              <div>
                <Camera onPhotoTaken={handlePhotoTaken} />
              </div>
            )}
            {image && <img src={image} width={"100%"} />}
            {jobDetail.shipment_image && (
              <img src={jobDetail.shipment_image} width={"100%"} />
            )}
            <div className="submit-section">
              <button
                className="btn btn-success"
                style={{ width: "100%" }}
                onClick={() => saveDetail(false)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default JobDetail;
