export const Icons: any = {
  // fuel: "https://orko-prod.s3.amazonaws.com/meta/images/ecodost/map/chargers_point.png",
  // fuelYellow:
  //   "https://orko-pilot.s3.amazonaws.com/meta/images/vlektra/map/chargers_point.png",
  fuel: require("./charger_point.png"),
  fuelYellow: require("./charger_point_yellow.png"),
  subtract: require("./subtract.png"),
  ignation: require("./ignation.png"),
  vector: require("./vector.png"),
  vehicle: require("./vehicle.png"),
  vehicleYellow: require("./vehicle_yellow.png"),
  vehicleGreen: require("./vehicle_green.png"),
  battery: require("./battery.png"),
  location: require("./location.png"),
  wheeler: require("./wheeler.png"),
  bike_placeholder: require("./bike_placeholder.png"),
  "vlektra-retro": require("./vlektra-retro.png"), //"https://orko-pilot.s3.amazonaws.com/md_models/20/1686718762.png",
  "vlektra-bolt": require("./vlektra-bolt-icon.png"), //"https://orko-pilot.s3.amazonaws.com/md_models/21/1686718815.png",
  "vlektra-1969": require("./vlektra-1969-icon.png"), //"https://orko-pilot.s3.amazonaws.com/md_models/22/1686718941.png",
  "nova_mobility-ecodost_electric_3_wheeler": require("./eccodost-loader-bike.png"), //"https://orko-prod.s3.amazonaws.com/md_models/22/1679394593.png",
  "mustang-mustang_-_model": require("./mustang.png"),
  "toyota-hilux_dl": require("./mustang.png"),
  "hyundai-porter_h100": require("./hyundai.png"),
  "icon-toyota-hilux_dl": require("./mustang.png"),
  "icon-hyundai-porter_h100": require("./hyundai.png"),
  "icon-vlektra-retro": require("./vlektra.png"),
  "icon-vlektra-bolt": require("./vlektra-bolt-icon.png"),
  "icon-vlektra-1969": require("./vlektra-1969-icon.png"),
  "icon-nova_mobility-ecodost_electric_3_wheeler": require("./icon-ecodost.png"),
  "icon-nova_mobility-electric_3_wheeler": require("./icon-ecodost.png"),
  "nova_mobility-electric_3_wheeler": require("./icon-ecodost.png"),
  "nova_mobility-ecodost_electric_2_wheeler": require("./nova-2-wheeler.png"),
  "icon-nova_mobility-ecodost_electric_2_wheeler": require("./nova-2-wheeler.png"),
  "sazgar-sazgar": require("./sazgar-loader.png"), // "https://orko-prod.s3.amazonaws.com/md_models/24/1684749288.png",
  "icon-sazgar-sazgar": require("./sazgar-loader.png"), //"https://orko-prod.s3.amazonaws.com/md_models/24/1684749288.png",
  "arrow-left": require("./arrow-left.png"),
  saving: require("./saving.png"),
  tree: require("./tree.png"),
  co2: require("./co2.png"),
  loader: require("./loader.gif"),
  orko_loader: require("./orko-loader.gif"),
  ecodost_loader: require("./Ecodost.gif"),
  sazgar_loader: require("./sazgarloader.gif"),
  currentLocation: require("./download.png"),
  startLocation: require("./start-location.png"),
  endLocation: require("./end-location.png"),
  crashScreen: require("./crash-screen.png"),

  address: require("./address.png"),
  camera: require("./camera.png"),
  camera_toggle: require("./camera_toggle.png"),
  close: require("./close.png"),
  phone: require("./phone.png"),
  email: require("./email.png"),
  double_checked: require("./double-checked.png"),
  warning: require("./warning.png"),
  rightArrow: require("./right-arrow.png"),
  imgAdd: require("./image-plus.png"),
};
